































import Vue from 'vue'
import { getNews, getNewsDetails } from '@/service/api'
import { Spin } from "ant-design-vue"
import MNewsItem from '@/components/mobile/m-newsItem.vue'
export default Vue.extend({
	name: 'newsDetails',
	data() {
		return {
			isLoading: true,
			details: {},
			day: '',
			month: '',
			year: '',
			id: 0,
			newest: [],
			nextNews: {}
		}
	},
	components: {
		Spin,
		MNewsItem
	},
	methods: {
		initData() {
			this.details = {}
			this.newest = []
			this.nextNews = {}
		},
		getDetails() {
			const language = window.sessionStorage.getItem('language') || 'zh-CN'
			this.isLoading = true
			getNewsDetails(this.id, language).then(res => {
				res.content = res.content.replace(/public.33.cn/g, 'public.yuan.org')
				this.details = res
				const { published_time } = res
				const _t = published_time.split(' ')[0]
				this.year = _t.split('-')[0]
				this.month = _t.split('-')[1]
				this.day = _t.split('-')[2]
				this.isLoading = false
			}).catch(e => {
				this.isLoading = false
			})
			
		},
		getNewsList() {
			const language = window.sessionStorage.getItem('language') || 'zh-CN'
			getNews(language).then(res => {
				const lists = res.rows
				this.newest = JSON.parse(JSON.stringify(lists)).splice(0, 3)
				for (let i = 0; i < res.rows.length; i++) {
					if (this.id == res.rows[i].id) {
						this.nextNews = i >= 1 ? res.rows[i-1] : res.rows[res.rows.length - 1]
					}
				}
			})
		}
	},
	created() {
		this.id = +this.$route.query.id
		this.getNewsList()
		this.getDetails()
	},
	watch: {
		$route(n, o) {
			this.id = n.query.id
			this.initData()
			this.getNewsList()
			this.getDetails()
		}
	}
})
